import React, { Fragment } from 'react'
import Menu from '../Components/Sidebar/Menu'
import Completelist from '../Components/Complete/Completelist'


function Completed() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3 side'>
                      <Menu/>
                    </div>
                    <div className='col-sm-9 main'>
<Completelist/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Completed