import React, { Fragment } from "react";
import EditProjectsCompleted from "./EditProjectsCompleted";

function EditComplete() {
  return (
    <Fragment>
      <div className="col-sm-12 hometext editss" data-aos="fade-left">
        <div className="row">
          <div className="col-sm-6">
            <h2>Edit Completed Projects</h2>
          </div>

          <div className="col-sm-12 texts comform ">
            <EditProjectsCompleted />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EditComplete;
