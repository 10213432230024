import React, { Fragment, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { EditCompleted } from "../../Redux/Actions/EditCompleted";
import { toast } from "react-toastify";
import {
  clearMessage,
  clearError,
} from "../../Redux/Reducers/EditCompletedReducer";

function EditProjectsCompleted() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);
  const [removedIds, setRemovedIds] = useState([]);
  const [fileList, setFileList] = useState([]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const { loading, error, message, uploaded } = useSelector(
    (state) => state.editCompleted
  );
  const [form] = Form.useForm();
  const { state } = useLocation();
  const list = state?.data;
  const onFinish = (values) => {
    const id = list._id;
    const formData = new FormData();

    formData.append("Name", values.Name);

    fileList?.forEach((file) => {
      formData.append("projectimages", file.originFileObj);
    });

    removedIds?.forEach((id) => {
      formData.append("DeletedImages", id);
    });
    videoList.forEach((file) => {
      formData.append("videos", file.originFileObj);
    });

    dispatch(EditCompleted({ formData, id }));
    // form.resetFields();
    setRemovedIds([]);
  };

  const onRemove = async (file) => {
    await setRemovedIds([...removedIds, file.id]);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const project = list?.CompletedProjectData || [];

  const onChange1 = ({ fileList: newFileList }) => {
    setVideoList(newFileList);
  };

  const videoLists = [];
  const fileLists = [];
  project.forEach((item, index) => {
    if (item.type && item.type.split("/")[0] === "image") {
      fileLists.push({
        uid: index,
        id: item._id,
        url: item.image,
        name: item.CompletedProjectid,
      });
    } else {
      videoLists.push({
        uid: index,
        id: item._id,
        url: item.image,
        name: item.CompletedProjectid,
      });
    }
  });

  form.setFieldsValue({
    Name: list?.Name,

    projectimages: list?.CompletedProjectData,
  });
  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/complete-projects");
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, message, navigate]);

  return (
    <Fragment>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        initialValues={{
          list,
        }}
      >
        <Form.Item
          name="Name"
          rules={[{ required: true, message: "Missing Location name" }]}
        >
          <Input placeholder="Location Name" />
        </Form.Item>

        <div className="row minerow">
          <h2>Images</h2>

          <div className="col-sm-12">
            <Form.Item name="image">
              <Upload
                action="/upload.do"
                listType="picture-card"
                beforeUpload={() => false}
                onChange={onChange}
                onRemove={onRemove}
                multiple
                accept="image/*"
                defaultFileList={fileLists}
              >
                <div>
                  {" "}
                  <PlusOutlined />
                  <div> Add Image </div>
                </div>
              </Upload>
            </Form.Item>

            <Button className="cross" type="dashed">
              {" "}
              X{" "}
            </Button>
          </div>
        </div>

        {/* video */}

        <div className="row minerow">
          <h2>Videos</h2>

          <div className="col-sm-12">
            <Form.Item name={"videos"}>
              <Upload
                action="/upload.do"
                listType="picture-card"
                multiple
                beforeUpload={() => false}
                onRemove={onRemove}
                defaultFileList={videoLists}
                onChange={onChange1}
                accept="video/*"
              >
                <div>
                  {" "}
                  <PlusOutlined />
                  <div> Add Videos </div>
                </div>
              </Upload>
            </Form.Item>

            <Button className="cross" type="dashed">
              {" "}
              X{" "}
            </Button>
          </div>
        </div>
        {/* 
      <div className="row minerow videosinput">
        <h2>Video</h2>
        <Form.List name="video">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div className="col-sm-12">
                  <Space key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, "videos"]}
                      rules={[
                        { required: true, message: "Missing Video Link" },
                      ]}
                    >
                      <Input placeholder="Youtube Video Link" />
                    </Form.Item>

                    <Button
                      className="cross"
                      type="dashed"
                      onClick={() => remove(name)}
                    >
                      {" "}
                      X{" "}
                    </Button>
                  </Space>
                </div>
              ))}
              <div className="col-sm-2">
                <Form.Item>
                  <Button
                    className="plusupload"
                    type="dashed"
                    onClick={() => add()}
                  >
                    {" "}
                    <PlusOutlined />{" "}
                  </Button>
                </Form.Item>
              </div>
            </>
          )}
        </Form.List>
      </div> */}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="save"
            disabled={loading}
          >
            {loading ? (
              <Spin indicator={antIcon} style={{ color: "#fff" }} />
            ) : (
              "Save"
            )}
          </Button>
        </Form.Item>
      </Form>
      {uploaded && (
        <div className="progress mt-2">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={uploaded}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${uploaded}%` }}
          >
            {`${uploaded}%`}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default EditProjectsCompleted;
