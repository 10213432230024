import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
};

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    changePasswordStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    changePasswordSuccess(state, action) {
      state.loading = false;
      state.message = "Data Updated Successfully";
    },

    changePasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  clearError,
  clearMessage,
} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
