import React, { Fragment } from 'react'
import Menu from '../Components/Sidebar/Menu'
import Underlist from '../Components/Under/Underlist'



function Under() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3 side'>
                      <Menu/>
                    </div>
                    <div className='col-sm-9 main'>
                        <Underlist/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Under