import React, { Fragment, useState, useEffect } from 'react'
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Home } from '../../Redux/Actions/HomeData';
import { HomeUpdate } from '../../Redux/Actions/HomeUpdateData';






function TextUpload() {

  const {message, error} = useSelector((state) => state.HomeUpdate) ;
  const { data } = useSelector((state) => state.Home);
  const Dispatch = useDispatch();

  const onFinish = async (values) => {
  console.log('Success:', values);
  const id = data._id 
   await  Dispatch(HomeUpdate({id , values}));
   Dispatch ( Home () )
  };


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // get Api
  const [form] = Form.useForm()
  form.setFieldsValue({
    Content: data?.Content,
    Category: data?.Category
  });
  useEffect(() => {
    Dispatch(Home());
  }, [Dispatch]);

  useEffect(() => {
    if (message) toast.success(message);
  }, [message]);

  useEffect(() => {
    if (error) toast.success(error);
  }, [error]);
  

  return (
    <Fragment>
      <Form name="basic"
        initialValues={
          data
        }
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item name="Content"
          rules={[{ required: true, message: 'Enter Heading First', },]} >
          <Input placeholder="Heading" />
        </Form.Item>

        <Form.Item name="Category"
          rules={[{ required: true, message: 'Enter Sub-Heading First', },]} >
          <Input placeholder="Sub-Heading" />
        </Form.Item>

        <Form.Item >
          <Button type="primary" htmlType="submit" className='save'> SAVE </Button>
        </Form.Item>
      </Form>
    </Fragment>
  )
}

export default TextUpload