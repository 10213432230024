import { combineReducers } from "redux";
import loginUserreducer from "./Reducers/LoginUserReducer";
import AboutReducer from "./Reducers/AboutReducer";
import AboutUpdate from "./Reducers/AboutUpdate";
import GetServiceReducer from "./Reducers/GetServiceReducer";
import ServicePost from "./Reducers/ServicePost";
import ServiceDelete from "./Reducers/ServiceDelete";
import ServiceUpdate from "./Reducers/ServiceUpdate";
import GetBrandsReducer from "./Reducers/GetBrandsReducer";
import postBrandsReducer from "./Reducers/PostBrandsReducer";
import DeleteBrandsReducer from "./Reducers/DeleteBrandsReducer";
import QouteReducer from "./Reducers/QouteReducer";
import HomeReducer from "./Reducers/HomeReducer";
import HomeUpdate from "./Reducers/HomeUpdate";
import EditBrandsReducer from "./Reducers/EditBrandsReducer";
import CompletedProjectReducer from "./Reducers/CompletedProjectReducer";
import PostCompletedReducer from "./Reducers/PostCompletedReducer";
import PostUnderReducer from "./Reducers/PostUnderReducer";
import UnderProjects from "./Reducers/UnderProjects";
import DeleteUnderReducer from "./Reducers/DeleteUnderReducer";
import AuthReducer from "./Reducers/AuthReducer";
import DeleteQueries from "./Reducers/DeleteQueries";
import DeleteCompletedReducer from "./Reducers/DeleteCompletedReducer";
import EditCompletedReducer from "./Reducers/EditCompletedReducer";
import EditUnderReducer from "./Reducers/EditUnderReducer";
import ChangePasswordReducer from "./Reducers/ChangePasswordReducer";
import GetDashboard from "./Reducers/GetDashboard";


const rootReducer = combineReducers({
  Login: loginUserreducer,
  About: AboutReducer,
  AboutUpdate: AboutUpdate,
  GetService: GetServiceReducer,
  ServicePost: ServicePost,
  ServiceDelete: ServiceDelete,
  ServiceUpdate: ServiceUpdate,
  GetBrands: GetBrandsReducer,
  postBrands: postBrandsReducer,
  DeleteBrands: DeleteBrandsReducer,
  Home: HomeReducer,
  HomeUpdate: HomeUpdate,
  Qoute: QouteReducer,
  editBrand: EditBrandsReducer,
  completed: CompletedProjectReducer,
  under: UnderProjects,
  postCompleted: PostCompletedReducer,
  postUnder: PostUnderReducer,
  deleteUnder: DeleteUnderReducer,
  auth: AuthReducer,
  deleteQuery: DeleteQueries,
  deleteCompleted: DeleteCompletedReducer,
  editCompleted: EditCompletedReducer,
  editUnder: EditUnderReducer,
  changePassword: ChangePasswordReducer,
  dashboard: GetDashboard,
});

export default rootReducer;
