
import {
  postUnderStart,
  postUnderSuccess,
  postUnderFailure,
  setUnderUploadedProgress,
  clearUpload,
} from "../Reducers/PostUnderReducer";
import axios from "axios";


export const PostUnder = (formData) => async (dispatch) => {
  try {
    dispatch(postUnderStart());

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/CreateUnderConstructionProjects`,
      formData,
      {
        onUploadProgress: (data) => {
          const progress = Math.round((data.loaded / data.total) * 100);
          dispatch(setUnderUploadedProgress(progress));
        },
      }
    );

    dispatch(postUnderSuccess(response.data));
    dispatch(clearUpload()); // Set uploaded to null after success
  } catch (error) {
    dispatch(postUnderFailure(error.response.data.message));
  }
};