import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAboutUpdateStart, createAboutUpdateSuccess, createAboutUpdateFailure,} from "../Reducers/AboutUpdate";
import axios from "axios";
import { toast } from "react-toastify";


export const AboutUpdate = createAsyncThunk(

  "aboutupdate",

  async ({id,values}, thunkAPI) => {

    try {
        console.log("data" , id)
        console.log("data" , values)
      thunkAPI.dispatch(createAboutUpdateStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/EditContent/${id}`,values
      );
      thunkAPI.dispatch(createAboutUpdateSuccess(response));
      // console.log("respponseedit", response)

      return response;
    } catch (error) {
      console.error(error)
      toast.error(error.response.message);
      thunkAPI.dispatch(createAboutUpdateFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);