import React, { Fragment } from "react";
import Menu from "../Components/Sidebar/Menu";
import PasswordForm from "../Components/ChangePassword/ChangePassword";

function ChangePassword() {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-3 side">
            <Menu />
          </div>
          <div className="col-sm-9 main">
            <PasswordForm />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ChangePassword;
