import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { ChangePassword } from "../../Redux/Actions/ChangePassword";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  clearError,
  clearMessage,
} from "../../Redux/Reducers/ChangePasswordReducer";

function TextUpload() {
  const dispatch = useDispatch();
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { error, message, loading } = useSelector(
    (state) => state.changePassword
  );

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  // get Api
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log(values);

    dispatch(ChangePassword(values));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [dispatch, error, message]);

  return (
    <Fragment>
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="editProfileForm"
      >
        <Form.Item
          label="Current Password"
          name="previouspassword"
          rules={[{ required: true, message: "Current Password First" }]}
        >
          <Input.Password placeholder="Current Password" />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newpassword"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["newpassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newpassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The passwords is not match!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="save">
            {loading ? (
              <Spin indicator={antIcon} style={{ color: "#fff" }} />
            ) : (
              "Save"
            )}
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
}

export default TextUpload;
