import { createAsyncThunk } from "@reduxjs/toolkit";
import { createServiceUpdateStart, createServiceUpdateSuccess, createServiceUpdateFailure,} from "../Reducers/ServiceUpdate";
import axios from "axios";
import { toast } from "react-toastify";


export const ServiceUpdate = createAsyncThunk(
  "ServiceUpdate",

  async ({ Pstservice, id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(createServiceUpdateStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}EditServices/${id}`,
        Pstservice
      );
      thunkAPI.dispatch(createServiceUpdateSuccess(response));
      // console.log("respponseedit", response)

      return response;
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
      thunkAPI.dispatch(
        createServiceUpdateFailure(error.response.data.message)
      ); // Dispatch the failure action
    }
  }
);