
import React, { Fragment } from 'react';
import EditUnderProjects from './EditUnderProjects';



function EditUnder() {
  
    return (
        <Fragment>
            <div className='col-sm-12 hometext editss' data-aos="fade-left">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h2>Edit Under Construction Projects</h2>
                    </div>


                    <div className='col-sm-12 texts comform '>
                  <EditUnderProjects/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default EditUnder;
