import React, { Fragment, useState } from 'react';
import { message } from 'antd';
import LeadTable from './LeadTable';


function Leadslist() {
    const [fileList, setFileList] = useState([]);

    const handleUploadChange = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        setFileList(info.fileList);
    };

    const handleUploadSubmit = () => {
        console.log('Uploaded Files:', fileList);
    };

    const props = {
        action: 'https://your-upload-url.com', // Replace with your actual upload API URL
        headers: {
            authorization: 'authorization-token',
        },
        onChange: handleUploadChange,
        fileList,
    };

    return (
        <Fragment>
            <div className='col-sm-12 hometext' data-aos="fade-left">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h2>Contact Leads Page</h2>
                    </div>
                    {/* <div className='col-sm-6'>
                        <div className='uploads'>
                            <h3>Background Image</h3>
                            <Upload {...props} beforeUpload={() => true}>
                                <Button icon={<UploadOutlined />}>Upload Image</Button>
                            </Upload>
                            <Button type="primary" onClick={handleUploadSubmit} className='save'> SAVE</Button>
                        </div>
                    </div> */}

                    <div className='col-sm-12 texts'>
                        <LeadTable/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Leadslist;
