import React, { Fragment } from 'react'
import Menu from '../Components/Sidebar/Menu'
import AddUnder from '../Components/AddUnderProject/AddUnder'



function AddUnderConstructedProject() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3 side'>
                        <Menu />
                    </div>
                    <div className='col-sm-9 main'>
                        <AddUnder/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddUnderConstructedProject 