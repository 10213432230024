import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const UnderProjectsSlice = createSlice({
  name: "underProjects",
  initialState,
  reducers: {
    underProjectStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    underProjectSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload.data;

    },
 

    underProjectFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  underProjectStart,
  underProjectSuccess,
  underProjectFailure,
  clearError,
  clearMessage,
} = UnderProjectsSlice.actions;

export default UnderProjectsSlice.reducer;