import React, { Fragment } from 'react'
import Login from '../Components/Login/Login'




function LoginPage() {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <Login />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LoginPage 