import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteCompletedStart,
  deleteCompletedSuccess,
  deleteCompletedFailure,
} from "../Reducers/DeleteCompletedReducer";
import axios from "axios";

export const DeleteCompleted = createAsyncThunk(
  "deleteCompleted",

  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteCompletedStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/HardDeleteCompletedProjects/${id}`
      );
      thunkAPI.dispatch(deleteCompletedSuccess(response));

      return response;
    } catch (error) {
      thunkAPI.dispatch(deleteCompletedFailure(error.response.data.message)); // Dispatch the failure action
    }
  }
);
