import React, { Fragment, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PostCompleted } from "../../Redux/Actions/PostCompleted";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  clearError,
  clearMessage,
} from "../../Redux/Reducers/PostCompletedReducer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AddProjectsCompleted() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [videoList, setVideoList] = useState([]);

  const { message, error, loading, uploaded } = useSelector(
    (state) => state.postCompleted
  );

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const onFinish = async (values) => {
    const formData = new FormData();

    formData.append("Name", values.service);

    fileList.forEach((file) => {
      formData.append("projectimages", file.originFileObj);
    });
    videoList.forEach((file) => {
      formData.append("videos", file.originFileObj);
    });
    // await axios.post(
    //   `${process.env.REACT_APP_BASE_URL}/CreateCompletedProjects`,
    //   formData,
    //   {
    //     onUploadProgress: (data) => {
    //       setUploaded(Math.round((data.loaded / data.total) * 100));
    //     },
    //   }
    // );
    dispatch(PostCompleted(formData));
  };
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onChange1 = ({ fileList: newFileList }) => {
    setVideoList(newFileList);
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/complete-projects");
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, message, navigate]);

  console.log(uploaded, "<====message");
  return (
    <Fragment>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          projects: [{ upload: "" }],
          video: [{ videos: "" }],
        }}
      >
        <Form.Item
          name="service"
          rules={[{ required: true, message: "Missing Location name" }]}
        >
          <Input placeholder="Location Name" />
        </Form.Item>

        <div className="row minerow">
          <h2>Images</h2>

          <div className="col-sm-12">
            <Form.Item>
              <Upload
                action="/upload.do"
                listType="picture-card"
                beforeUpload={() => false}
                onChange={onChange}
                multiple
                accept="image/*"
              >
                <div>
                  {" "}
                  <PlusOutlined />
                  <div> Add Image </div>
                </div>
              </Upload>
            </Form.Item>

            <Button className="cross" type="dashed">
              {" "}
              X{" "}
            </Button>
          </div>
        </div>

        {/* video */}

        <div className="row minerow">
          <h2>Videos</h2>

          <div className="col-sm-12">
            <Form.Item>
              <Upload
                action="/upload.do"
                listType="picture-card"
                beforeUpload={() => false}
                onChange={onChange1}
                accept="video/*"
                multiple
              >
                <div>
                  {" "}
                  <PlusOutlined />
                  <div> Add Videos </div>
                </div>
              </Upload>
            </Form.Item>

            <Button className="cross" type="dashed">
              {" "}
              X{" "}
            </Button>
          </div>
        </div>

        {/* 
        <div className="row minerow videosinput">
          <h2>Video</h2>
          <Form.List name="video">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="col-sm-12">
                    <Space key={key}>
                      <Form.Item
                        {...restField}
                        name={[name, "videos"]}
                        rules={[
                          { required: true, message: "Missing Video Link" },
                        ]}
                      >
                        <Input placeholder="Youtube Video Link" />
                      </Form.Item>

                      <Button
                        className="cross"
                        type="dashed"
                        onClick={() => remove(name)}
                      >
                        {" "}
                        X{" "}
                      </Button>
                    </Space>
                  </div>
                ))}
                <div className="col-sm-2">
                  <Form.Item>
                    <Button
                      className="plusupload"
                      type="dashed"
                      onClick={() => add()}
                    >
                      {" "}
                      <PlusOutlined />{" "}
                    </Button>
                  </Form.Item>
                </div>
              </>
            )}
          </Form.List>
        </div> */}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="save"
            disabled={loading}
          >
            {" "}
            {loading ? (
              <Spin indicator={antIcon} style={{ color: "#ffff" }} />
            ) : (
              "Save"
            )}
          </Button>
        </Form.Item>
        {uploaded && (
          <div className="progress mt-2">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={uploaded}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${uploaded}%` }}
            >
              {`${uploaded}%`}
            </div>
          </div>
        )}
      </Form>
    </Fragment>
  );
}

export default AddProjectsCompleted;
