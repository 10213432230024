import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  uploaded: null,
};

const editCompletedSlice = createSlice({
  name: "editCompleted",
  initialState,
  reducers: {
    editCompletedStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    editCompletedSuccess(state, action) {
      state.loading = false;
      state.message = "Data Updated Successfully";
      state.uploaded = null;
    },
    setEditCompletedUploadedProgress(state, action) {
      state.uploaded = action.payload;
    },

    editCompletedFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
    clearUpload: (state) => {
      state.uploaded = null;
    },
  },
});

export const {
  editCompletedStart,
  editCompletedSuccess,
  editCompletedFailure,
  clearError,
  clearMessage,
  clearUpload,
  setEditCompletedUploadedProgress,
} = editCompletedSlice.actions;

export default editCompletedSlice.reducer;
