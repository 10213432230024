import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const ServiceSlice = createSlice({
  name: "Service",
  initialState,
  reducers: {
    createServiceStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    createServiceSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload.data;
      state.message = action.payload;
      // console.log("asdsad" ,action.payload)
    },
    logoutServiceSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload;
      state.token = null;
    },

    createServiceFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  createServiceStart,
  createServiceSuccess,
  logoutServiceSuccess,
  createServiceFailure,
  clearError,
  clearMessage,
} = ServiceSlice.actions;

export default ServiceSlice.reducer;