import React, { Fragment } from 'react'
import Menu from '../Components/Sidebar/Menu'
import EditComplete from '../Components/Edit Complete/EditComplete'



function EditCompleteProjects() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3 side'>
                        <Menu />
                    </div>
                    <div className='col-sm-9 main'>
                        <EditComplete/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditCompleteProjects 