import { Button, Form, Upload } from "antd";
import React, { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PostBrands } from "../../Redux/Actions/PostBrands";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { EditBrands } from "../../Redux/Actions/EditBrands";
import { GetBrands } from "../../Redux/Actions/GetBrands";
import {
  clearError,
  clearMessage,
} from "../../Redux/Reducers/EditBrandsReducer";
export const EditServiceLogo = ({ setEditModeMap, itemIds, currentPage }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading, error, message } = useSelector((state) => state.editBrand);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const onFinish = async (values) => {
    const id = itemIds;
    const formData = new FormData();

    // Append the "Name" as a string
    formData.append("Name", "bellenciago");

    // Append the image file from the values object
    formData.append("image", values.image.file);

    await dispatch(EditBrands({ id, formData }));
    form.resetFields();
    dispatch(GetBrands({ currentPage }));
    setEditModeMap({});
  };

  const onFinishFailed = (errorInfo) => {
    toast.error(errorInfo);
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
    if (error) {
      toast.success(error);

      dispatch(clearError());
    }
  }, [dispatch, message, error]);

  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
    >
      <div className="row">
        <div className="col-sm-6">
          <Form.Item
            name="image"
            rules={[{ required: true, message: "Image is Required" }]}
          >
            <Upload
              action="/upload.do"
              listType="picture-card"
              // defaultFileList={defaultFileList}
              beforeUpload={() => false}
              maxCount={1}
            >
              <div>
                {" "}
                <PlusOutlined />
                <div> Add Logo </div>
              </div>
            </Upload>
          </Form.Item>
        </div>

        <div className="col-sm-6">
          <Form.Item>
            <Button className="save service" type="dashed" htmlType="submit">
              {loading ? (
                <Spin indicator={antIcon} style={{ color: "#000" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
