import { createAsyncThunk } from "@reduxjs/toolkit";
import { postBrandStart, postBrandSuccess, postBrandFailure} from "../Reducers/PostBrandsReducer";
import axios from "axios";


export const PostBrands = createAsyncThunk(

  "postBrands",

  async (formData, thunkAPI) => {
    try {
        console.log("data" , formData)
      thunkAPI.dispatch(postBrandStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/CreateBrand`,formData
      );
      thunkAPI.dispatch(postBrandSuccess(response));
      console.log("respponse", response)

      return response;
    } catch (error) {
  
      thunkAPI.dispatch(postBrandFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);