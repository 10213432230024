import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  isAuthenticated: false,
  token: null,
};

const loginUserSlice = createSlice({
  name: "loginuser",
  initialState,
  reducers: {
    createLoginUserStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
      state.isAuthenticated = false;
    },

    createLoginUserSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
      state.message = "Login successfully!";
      state.token = action.payload.data.tokens.access.token;
    },
    logoutLoginUserSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.message = action.payload;
      state.token = null;
    },

    createLoginUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
      state.isAuthenticated = false;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  createLoginUserStart,
  createLoginUserSuccess,
  logoutLoginUserSuccess,
  createLoginUserFailure,
  clearError,
  clearMessage,
} = loginUserSlice.actions;

export default loginUserSlice.reducer;