import { createAsyncThunk } from "@reduxjs/toolkit";
import { createServiceDeleteStart, createServiceDeleteSuccess, createServiceDeleteFailure,} from "../Reducers/ServiceDelete";
import axios from "axios";
import { toast } from "react-toastify";


export const ServiceDeleteData = createAsyncThunk(

  "ServiceDelete",

  async (id, thunkAPI) => {

    try {
    
      thunkAPI.dispatch(createServiceDeleteStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/HardDeleteServices/${id}`
      );
      console.log("delete id" , id)
      thunkAPI.dispatch(createServiceDeleteSuccess(response));
      // console.log("respponseedit", response)

      return response;
    } catch (error) {
      console.error(error)
      toast.error(error.response.message);
      thunkAPI.dispatch(createServiceDeleteFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);