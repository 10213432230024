import React, { Fragment } from 'react'
import Menu from '../Components/Sidebar/Menu'
import HomeText from '../Components/Home/HomeText'
import AboutText from '../Components/About/AboutText'
import ViewLeads from '../Components/ViewLeads/ViewLeads'



function ViewAllLeads() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3 side'>
                        <Menu />
                    </div>
                    <div className='col-sm-9 viewsmine main' data-aos="fade-left">
                        <ViewLeads/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ViewAllLeads 