import { createAsyncThunk } from "@reduxjs/toolkit";
import { createServicePostStart, createServicePostSuccess, createServicePostFailure,} from "../Reducers/ServicePost";
import axios from "axios";
import { toast } from "react-toastify";


export const ServicePost = createAsyncThunk(

  "servicepost",

  async (values, thunkAPI) => {

    try {
        // console.log("data1" , values)
      thunkAPI.dispatch(createServicePostStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/CreateServices`,values
      );
      thunkAPI.dispatch(createServicePostSuccess(response));
      // console.log("respponseedit", response)

      return response;
    } catch (error) {
      console.error(error)
      toast.error(error.response.message);
      thunkAPI.dispatch(createServicePostFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);