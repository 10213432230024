import { createAsyncThunk } from "@reduxjs/toolkit";
import { createServiceStart, createServiceSuccess, createServiceFailure,} from "../Reducers/GetServiceReducer";
import axios from "axios";
import { toast } from "react-toastify";


export const GetService = createAsyncThunk(

  "Service",

  async ({currentPage}, thunkAPI) => {
    try {
        // console.log("data" , Category)
        // console.log("data" , values)
      thunkAPI.dispatch(createServiceStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/GetServicesListing?page=${currentPage}`
      );
      thunkAPI.dispatch(createServiceSuccess(response));
      // console.log("respponse", response)

      return response;
    } catch (error) {
      console.error(error)
      toast.error(error.response.message);
      thunkAPI.dispatch(createServiceFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);