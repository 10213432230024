import { createAsyncThunk } from "@reduxjs/toolkit";
import { underProjectStart, underProjectSuccess, underProjectFailure,} from "../Reducers/UnderProjects";
import axios from "axios";
import { toast } from "react-toastify";


export const UnderProjects = createAsyncThunk(

  "underProjects",

  async ({currentPage}, thunkAPI) => {
    try {
        // console.log("data" , Category)
        // console.log("data" , values)
      thunkAPI.dispatch(underProjectStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/GetUnderConstructionProjectsListing?page=${currentPage}`
      );
      thunkAPI.dispatch(underProjectSuccess(response));
      // console.log("respponse", response)

      return response;
    } catch (error) {
      console.error(error)
      toast.error(error.response.message);
      thunkAPI.dispatch(underProjectFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);