import axios from "axios";
import { clearUpload, postCompletedFailure, postCompletedStart, postCompletedSuccess, setCompletedUploadedProgress } from "../Reducers/PostCompletedReducer";

export const PostCompleted = (formData) => async (dispatch) => {
  try {
    dispatch(postCompletedStart());

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/CreateCompletedProjects`,
      formData,
      {
        onUploadProgress: (data) => {
          const progress = Math.round((data.loaded / data.total) * 100);
          dispatch(setCompletedUploadedProgress(progress));
        },
      }
    );

    dispatch(postCompletedSuccess(response.data));
    dispatch(clearUpload()); // Set uploaded to null after success
  } catch (error) {
    dispatch(postCompletedFailure(error.response.data.message));
  }
};

