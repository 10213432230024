import React, { Fragment, useEffect } from 'react'
import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { about } from '../../Redux/Actions/AboutData';
import { AboutUpdate } from '../../Redux/Actions/AboutUpdateData';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const { TextArea } = Input;

function TextUploadAbout() {
  const {message, error} = useSelector((state) => state.AboutUpdate) ;

  const {data} = useSelector((state) => state.About) ;



  const Dispatch = useDispatch();
  const onFinish = async (values) => {
  console.log('Success:', values);
  const id = data._id 
   await  Dispatch(AboutUpdate({id , values}));
   Dispatch ( about () )
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


// Get API Data
const  [form] = Form.useForm()
form.setFieldsValue({
  Content: data?.Content,
});
useEffect(()=> { Dispatch ( about () ) }, [])

useEffect(()=> {
  if(message)
 toast.success(message)
},[message])


useEffect(()=> {
  if(error)
 toast.success(error)
},[])


  return (
    <Fragment>
      <Form name="basic"
        initialValues={
          data
        }
        form= {form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
  >

    <Form.Item  name="Content"
      rules={[ {  required: true, message: 'Enter Paragraph First',  }, ]} >
       <TextArea rows={6} />
    </Form.Item>

    <Form.Item name="Category" initialValue="aboutus">
      <Input type="hidden" />
    </Form.Item>



    <Form.Item >
      <Button type="primary" htmlType="submit" className='save'> SAVE </Button>
    </Form.Item>
  </Form>
    </Fragment>
  )
}

export default TextUploadAbout