import React, { Fragment, useState } from 'react';
import Logo from "../../Assets/Logo.png";
import { Link, NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch } from "react-redux";
import { logOutSuccess } from "../../Redux/Reducers/AuthReducer";
import Cookies from "js-cookie";

function Menu() {
  const [activeItem, setActiveItem] = useState(null);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const menuItems = [
    { label: "Dashboard", to: "/dashboard", index: 0 },
    { label: "HOME", to: "/home", index: 1 },
    { label: "About", to: "/about", index: 2 },
    { label: "Service", to: "/service", index: 3 },
    { label: "Completed Projects", to: "/complete-projects", index: 4 },
    {
      label: "Under Construction Projects",
      to: "/under-construction-projects",
      index: 5,
    },
    { label: "Leads", to: "/lead", index: 6 },
    { label: "Change Password", to: "/change-password", index: 7 },
  ];

  const handleLogout = async () => {
    await dispatch(logOutSuccess());
    Cookies.remove("token", { path: "/" });
  };

  return (
    <Fragment>
      <div className="col-sm-12 sidebar desktop">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>

        <ul>
          {menuItems.map((item) => (
            <li key={item.index}>
              <NavLink
                to={item.to}
                activeClassName="active" // Apply the 'active' class to the NavLink when it matches the current route
                onClick={() => {
                  handleItemClick(item.index);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>

        <Link to="/">
          <button className="logout" onClick={handleLogout}>
            Logout
          </button>
        </Link>
      </div>

      <div className="mobile">
        <Button variant="primary" onClick={handleShow}>
          Menu
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <div className="col-sm-12 sidebar">
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>

              <ul>
                {menuItems.map((item) => (
                  <li key={item.index}>
                    <NavLink
                      to={item.to}
                      activeClassName="active" // Apply the 'active' class to the NavLink when it matches the current route
                      onClick={() => {
                        handleItemClick(item.index);
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
              <Link to="/">
                <button onClick={handleLogout} className="logout">
                  Logout
                </button>
              </Link>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </Fragment>
  );
}

export default Menu;