import React, { Fragment, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Qoute } from '../../Redux/Actions/QouteData';

const { TextArea } = Input;

const onFinish = (values) => {
  console.log('Success:', values);
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const goBack = () => {
  window.history.back();
};

function ViewLeads() {
  const { id } = useParams(); // Get the ID from the route parameter
  const { data } = useSelector((state) => state.Qoute);
  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(Qoute());
  }, [Dispatch]);

  const matchingItem = data?.data?.find((item) => item._id === id);

  return (
    <Fragment>
      {matchingItem && (
        <div className='col-sm-12 texts lists leads viewleads' key={matchingItem._id}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="name"
              initialValue={matchingItem.Name}
              rules={[{ required: true, message: 'Enter Name First' }]}
            >
              <Input placeholder="NAME" disabled />
            </Form.Item>

            <Form.Item
              name="e-mail"
              initialValue={matchingItem.Email}
              rules={[{ required: true, message: 'Enter E-MAIL First' }]}
            >
              <Input placeholder="E-MAIL" disabled />
            </Form.Item>

            <Form.Item
              name="contact"
              initialValue={matchingItem.Contact}
              rules={[{ required: true, message: 'Enter Sub-Heading First' }]}
            >
              <Input placeholder="CONTACT" disabled />
            </Form.Item>

            <Form.Item
              name="message"
              initialValue={matchingItem.Message}
              rules={[{ required: true, message: 'Lorem ipsum is a dummy text' }]}
            >
              <TextArea rows={6} placeholder='Lorem ipsum is a dummy text' disabled />
            </Form.Item>
          </Form>

          <Button type="primary" htmlType="submit" className='save' onClick={goBack}>
            Back To List
          </Button>
        </div>
      )}
    </Fragment>
  );
}

export default ViewLeads;