import React, { Fragment } from "react";
import AddUnderProjects from "./AddUnderProjects";

function AddUnder() {
  return (
    <Fragment>
      <div className="col-sm-12 hometext editss" data-aos="fade-left">
        <div className="row">
          <div className="col-sm-6">
            <h2>Add Under Construction Projects</h2>
          </div>

          <div className="col-sm-12 texts comform ">
            <AddUnderProjects />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddUnder;
