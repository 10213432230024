import { createAsyncThunk } from "@reduxjs/toolkit";
import { projectStart, projectSuccess, projectFailure,} from "../Reducers/CompletedProjectReducer";
import axios from "axios";
import { toast } from "react-toastify";


export const CompeletedProjects = createAsyncThunk(

  "CompletedProjects",

  async ({currentPage}, thunkAPI) => {
    try {
        // console.log("data" , Category)
        // console.log("data" , values)
      thunkAPI.dispatch(projectStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/GetCompletedProjectsListing?page=${currentPage}`
      );
      thunkAPI.dispatch(projectSuccess(response));
      // console.log("respponse", response)

      return response;
    } catch (error) {
      console.error(error)
      toast.error(error.response.message);
      thunkAPI.dispatch(projectFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);