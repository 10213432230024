import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDashboard } from "../../Redux/Actions/GetDashboard";

function Landing() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.dashboard);

  console.log(data);
  useEffect(() => {
    dispatch(GetDashboard());
  }, [dispatch]);

  return (
    <Fragment>
      <div className="col-sm-12 dashboard">
        <div className="row">
          <div className="col-sm-6 boxes" data-aos="fade-down">
            <h3>
              {data?.UnderConstructionProjectData
                ? data?.UnderConstructionProjectData
                : 0}
            </h3>
            <p>No of under construction project</p>
          </div>

          <div className="col-sm-6 boxes b2" data-aos="fade-down">
            <h3>
              {data?.CompletedProjectsData ? data?.CompletedProjectsData : 0
              }
            </h3>
            <p>No of completed project</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Landing;
