import { createAsyncThunk } from "@reduxjs/toolkit";
import { editBrandStart, editBrandSuccess, editBrandFailure} from "../Reducers/EditBrandsReducer.js";
import axios from "axios";


export const EditBrands = createAsyncThunk(

  "editBrands",

  async ({id,formData}, thunkAPI) => {
    try {
        console.log("data" , formData)
      thunkAPI.dispatch(editBrandStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/EditBrand/${id}`,formData
      );
      thunkAPI.dispatch(editBrandSuccess(response));
      console.log("respponse", response)

      return response;
    } catch (error) {
  
      thunkAPI.dispatch(editBrandFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);