import React, { Fragment } from "react";

import TextUpload from "./TextUpload";

function HomeText() {
  return (
    <Fragment>
      <div className="col-sm-12 hometext" data-aos="fade-left">
        <div className="row">
          <div className="col-sm-6">
            <h2>Change Password</h2>
          </div>

          <div className="col-sm-12 texts">
            <TextUpload />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default HomeText;
