import React, { Fragment, useState } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import TextUpload from './TextUpload';

function HomeText() {
  const [fileList, setFileList] = useState([]);

  const handleUploadChange = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    setFileList(info.fileList);
  };

  return (
    <Fragment>
      <div className="col-sm-12 hometext" data-aos="fade-left">
        <div className="row">
          <div className="col-sm-6">
            <h2>Edit Home Page</h2>
          </div>
          {/* <div className='col-sm-6'>
                        <div className='uploads'>
                            <h3>Background Image</h3>
                            <Upload {...props} beforeUpload={() => true}> 
                                <Button icon={<UploadOutlined />}>Upload Image</Button>
                            </Upload>
                            <Button type="primary" onClick={handleUploadSubmit} className='save'> SAVE</Button>
                        </div>
                    </div> */}

          <div className="col-sm-12 texts">
            <TextUpload />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default HomeText;
