import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
};

const PostUnderSlice = createSlice({
  name: "postUnder",
  initialState,
  reducers: {
    postUnderStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
      state.uploaded = null;
    },

    postUnderSuccess(state, action) {
      state.loading = false;
      state.message = "Data Uploaded Successfully";
      state.uploaded = null;
    },

    postUnderFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    setUnderUploadedProgress(state, action) {
      state.uploaded = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
    clearUpload: (state) => {
      state.uploaded = null;
    },
  },
});

export const {
  setUnderUploadedProgress,
  postUnderStart,
  postUnderSuccess,
  postUnderFailure,
  clearUpload,
  clearError,
  clearMessage,
} = PostUnderSlice.actions;

export default PostUnderSlice.reducer;