import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,

};

const DeleteBrandSlice = createSlice({
  name: "deleteBrands",
  initialState,
  reducers: {
    deleteBrandStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    deleteBrandSuccess(state, action) {
      state.loading = false
      state.message = action.payload;
 
    },

    deleteBrandFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  deleteBrandStart,
  deleteBrandSuccess,
  deleteBrandFailure,
  clearError,
  clearMessage,
} = DeleteBrandSlice.actions;

export default DeleteBrandSlice.reducer;