import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
};

const DeleteCompletedSlice = createSlice({
  name: "deleteCompleted",
  initialState,
  reducers: {
    deleteCompletedStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    deleteCompletedSuccess(state, action) {
      state.loading = false;
      state.message = "Data Deleted Successfully";
    },

    deleteCompletedFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  deleteCompletedStart,
  deleteCompletedSuccess,
  deleteCompletedFailure,
  clearError,
  clearMessage,
} = DeleteCompletedSlice.actions;

export default DeleteCompletedSlice.reducer;
