import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
};

const editUnderSlice = createSlice({
  name: "editUnder",
  initialState,
  reducers: {
    editUnderStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    editUnderSuccess(state, action) {
      state.loading = false;
      state.message = "Data Updated Successfully";
    },

    editUnderFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    setEditUnderUploadedProgress(state, action) {
      state.uploaded = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
    clearUpload: (state) => {
      state.uploaded = null;
    },
  },
});

export const {
  editUnderStart,
  editUnderSuccess,
  editUnderFailure,
  clearError,
  clearMessage,
  clearUpload,
  setEditUnderUploadedProgress,
} = editUnderSlice.actions;

export default editUnderSlice.reducer;
