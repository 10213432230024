import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteBrandStart, deleteBrandSuccess, deleteBrandFailure} from "../Reducers/DeleteBrandsReducer.js";
import axios from "axios";


export const DeleteBrands = createAsyncThunk(

  "deleteBrands",

  async (id, thunkAPI) => {
    try {
       
      thunkAPI.dispatch(deleteBrandStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/HardDeleteBrand/${id}`
      );
      thunkAPI.dispatch(deleteBrandSuccess(response));
    

      return response;
    } catch (error) {
  
      thunkAPI.dispatch(deleteBrandFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);