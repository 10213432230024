import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { GetService } from '../../Redux/Actions/GetService';
import { useSelector, useDispatch } from 'react-redux';
import { ServicePost } from '../../Redux/Actions/ServicePostAction';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { clearMessage } from '../../Redux/Reducers/ServicePost';
import { ServiceDeleteData } from '../../Redux/Actions/DeleteService';
import { ServiceUpdate } from '../../Redux/Actions/ServiceUpdateData';
import { Pagination } from 'antd';
import DeleteModal from "../Modal/DeleteModal";

function ServiceAdd() {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();

  const [form] = Form.useForm();

  const { message, error } = useSelector((state) => state.ServicePost);
  const { data } = useSelector((state) => state.GetService);
  const DeleteMessane = useSelector((state) => state.ServiceDelete.message);

  const [editedItem, setEditedItem] = useState({ id: null, value: "" });
  const [newData, setNewData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemId, setItemId] = useState(null);

  const Dispatch = useDispatch();
  const onFinish = async (values) => {
    console.log("Success:", values);
    await Dispatch(ServicePost(values));
    dispatch(GetService({ currentPage }));
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleEdit = (itemId, itemValue) => {
    setEditedItem({
      id: itemId,
      value: itemValue,
    });
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    dispatch(GetService({ currentPage }));
  }, [dispatch, currentPage]);
  useEffect(() => {
    if (message) toast.success(message);
    dispatch(clearMessage());
  }, [dispatch, message]);

  useEffect(() => {
    if (DeleteMessane) toast.success(DeleteMessane);
    dispatch(clearMessage());
  }, [DeleteMessane, dispatch]);

  const handleDelete = async (itemId) => {
    try {
      await dispatch(ServiceDeleteData(itemId));
      dispatch(GetService({ currentPage }));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      const Pstservice = { Name: editedItem.value };
      const id = editedItem.id;
      await dispatch(ServiceUpdate({ Pstservice, id }));
      // Clear the edited item state
      setEditedItem({ id: null, value: "" });
      // Refresh the data
      dispatch(GetService({ currentPage }));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (error) toast.success(error);
  }, [error]);

  useEffect(() => {
    setTotalCount(data.totalcount);
  }, [data.totalcount]);

  return (
    <Fragment>
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Title"
          name="Name"
          rules={[{ required: true, message: "Enter Paragraph First" }]}
        >
          <Input rows={6} placeholder="Title" />
        </Form.Item>

        <Form.Item>
          <Button type="save service" htmlType="submit" className="save">
            {" "}
            Add Service{" "}
          </Button>
        </Form.Item>
      </Form>

      {newData && (
        <div className="row getnames">
          <div className="col-sm-12">
            <h2>{newData.Name}</h2>
          </div>
        </div>
      )}

      <div className="row getnames">
        {data?.data?.map((item) => (
          <div className="col-sm-12" key={item._id}>
            {editedItem.id === item._id ? ( // Display an input field when editing
              <>
                <div className="row editrow">
                  <div className="col-sm-9">
                    <Input
                      value={editedItem.value}
                      onChange={(e) =>
                        setEditedItem({ ...editedItem, value: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <button type="primary" onClick={handleSaveEdit}>
                      {" "}
                      Save Edit
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-sm-8">
                    <h2>{item.Name}</h2>
                  </div>
                  <div className="col-sm-2">
                    <button
                      type="danger"
                      onClick={() => {
                        setItemId(item._id);
                        setIsModalVisible(true);
                      }}
                    >
                      {" "}
                      Delete{" "}
                    </button>
                  </div>
                  <div className="col-sm-2">
                    <button
                      type="primary"
                      onClick={() => handleEdit(item._id, item.Name)}
                    >
                      {" "}
                      Edit
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
        <Pagination
          total={totalCount}
          current={currentPage}
          onChange={onPageChange}
          showTotal={(total) => `Total ${total} items`}
        />

        <DeleteModal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          handleDelete={handleDelete}
          id={itemId}
        />
      </div>
    </Fragment>
  );
}

export default ServiceAdd;