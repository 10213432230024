import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const AboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    createAboutStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    createAboutSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload.data.data[0];
      state.message = action.payload;
    //   console.log("asdsad" ,action.payload)
    },
    logoutAboutSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.message = action.payload;
      state.token = null;
    },

    createAboutFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  createAboutStart,
  createAboutSuccess,
  logoutAboutSuccess,
  createAboutFailure,
  clearError,
  clearMessage,
} = AboutSlice.actions;

export default AboutSlice.reducer;