
import {
  editCompletedStart,
  editCompletedSuccess,
  editCompletedFailure,
  setEditCompletedUploadedProgress,
  clearUpload,
} from "../Reducers/EditCompletedReducer";
import axios from "axios";

// export const EditCompleted = createAsyncThunk(
//   "editCompleted",

//   async ({ formData, id }, thunkAPI) => {
//     try {
//       console.log("data", formData);
//       thunkAPI.dispatch(editCompletedStart()); // Dispatch the start action
//       // Make your API request here, e.g., using fetch or axios
//       const response = await axios.put(
//         `${process.env.REACT_APP_BASE_URL}/EditCompletedProjects/${id}`,
//         formData
//       );
//       thunkAPI.dispatch(editCompletedSuccess(response));
//       console.log("respponse", response);

//       return response;
//     } catch (error) {
//       thunkAPI.dispatch(editCompletedFailure(error.response.data.message)); // Dispatch the failure action
//     }
//   }
// );



export const EditCompleted =
  ({ formData, id }) =>
  async (dispatch) => {
    try {
      dispatch(editCompletedStart());

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/EditCompletedProjects/${id}`,
        formData,
        {
          onUploadProgress: (data) => {
            const progress = Math.round((data.loaded / data.total) * 100);
            dispatch(setEditCompletedUploadedProgress(progress));
          },
        }
      );

      dispatch(editCompletedSuccess(response));
      dispatch(clearUpload()); // Set uploaded to null after success
    } catch (error) {
      dispatch(editCompletedFailure(error.response.data.message)); //
    }
  };
