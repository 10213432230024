import React, { Fragment,useEffect,useState } from 'react'
import View from "../../Assets/View.png"
import { Link } from 'react-router-dom'
import { Qoute } from '../../Redux/Actions/QouteData'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux';
import { Pagination } from "antd";
import Delete from "../../Assets/Delete.png";
import { QuerisDeleteData } from "../../Redux/Actions/DeleteQueries";
import { toast } from "react-toastify";
import { clearError, clearMessage } from "../../Redux/Reducers/DeleteQueries";
import DeleteModal from "../Modal/DeleteModal";

function LeadTable() {
  const { data } = useSelector((state) => state.Qoute);
  const { message, error } = useSelector((state) => state.deleteQuery);
  const Dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemId, setItemId] = useState(null);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    await Dispatch(QuerisDeleteData(id));
    Dispatch(Qoute({ currentPage }));
  };

  useEffect(() => {
    Dispatch(Qoute({ currentPage }));
  }, [Dispatch, currentPage]);

  useEffect(() => {
    setTotalCount(data.totalcount);
  }, [data.totalcount]);

  useEffect(() => {
    if (message) {
      toast.success(message);

      Dispatch(clearMessage());
    }
    if (error) {
      toast.error(error);

      Dispatch(clearError());
    }
  }, [Dispatch, error, message]);

  return (
    <Fragment>
      <div className="lists leads myleads">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>E-mail</th>
              <th>Contact</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((item) => (
              <tr>
                <td>
                  <p>{item.Name}</p>
                </td>
                <td>{item.Email}</td>
                <td>{item.Contact}</td>
                <td>
                  <Link to={`/view-leads/${item._id}`}>
                    <img src={View} alt="view" />{" "}
                  </Link>
                  {/* <Link to="/view-leads"><img src={View} alt='view' /> </Link> */}
                  <img
                    src={Delete}
                    onClick={() => {
                      setItemId(item._id); // Set the ID here (replace with your actual ID logic)
                      setIsModalVisible(true);
                    }}
                    alt="view"
                    // onClick={() => handleDelete(item._id)}
                  />{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          total={totalCount}
          current={currentPage}
          onChange={onPageChange}
          showTotal={(total) => `Total ${total} items`}
        />
      </div>

      <DeleteModal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        handleDelete={handleDelete}
        id={itemId}
      />
    </Fragment>
  );
}

export default LeadTable