import React, { Fragment, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Image, Pagination, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GetBrands } from "../../Redux/Actions/GetBrands";
import { PostBrands } from "../../Redux/Actions/PostBrands";
import { DeleteBrands } from "../../Redux/Actions/DeleteBrands";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { EditServiceLogo } from "./EditServiceLogo";
import DeleteModal from "../Modal/DeleteModal";

function ServiceLogo() {
  const [editModeMap, setEditModeMap] = useState({});
  const [itemIds, setItemIds] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemId, setItemId] = useState(null);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { data } = useSelector((state) => state.GetBrands);
  const { loading } = useSelector((state) => state.postBrands);

  useEffect(() => {
    dispatch(GetBrands({ currentPage }));
  }, [currentPage, dispatch]);

  const onFinish = async (values) => {
    const formData = new FormData();

    // Append the "Name" as a string
    formData.append("Name", "bellenciago");

    // Append the image file from the values object
    formData.append("image", values.image.file);

    await dispatch(PostBrands(formData));
    form.resetFields();
    dispatch(GetBrands({ currentPage }));
  };

  const onFinishFailed = (errorInfo) => {
    toast.error(errorInfo);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    await dispatch(DeleteBrands(id));
    dispatch(GetBrands({ currentPage }));
  };

  const handleEditClick = (itemId) => {
    setItemIds(itemId);
    setEditModeMap((prevState) => ({
      ...prevState,
      [itemId]: true,
    }));
  };

  const handleCloseUpload = async (itemId) => {
    setEditModeMap((prevState) => ({
      ...prevState,
      [itemId]: false,
    }));
  };
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setTotalCount(data.totalcount);
  }, [data.totalcount]);
  console.log(data, "<===== item ids");

  return (
    <Fragment>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <div className="row">
          <div className="col-sm-12">
            <Form.Item
              name="image"
              rules={[{ required: true, message: "Image is Required" }]}
            >
              <Upload
                action="/upload.do"
                listType="picture-card"
                // defaultFileList={defaultFileList}
                beforeUpload={() => false}
                maxCount={1}
              >
                <div>
                  {" "}
                  <PlusOutlined />
                  <div> Add Logo </div>
                </div>
              </Upload>
            </Form.Item>
          </div>

          <div className="col-sm-12">
            <Form.Item>
              <Button className="save service" type="dashed" htmlType="submit">
                {loading ? (
                  <Spin indicator={antIcon} style={{ color: "#000" }} />
                ) : (
                  "Add Logo"
                )}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <div className="row getnames">
        <div className="row getnames">
          {data?.data?.map((item) => (
            <div className="col-sm-12" key={item._id}>
              <>
                <div className="row editrow">
                  <div className="col-sm-4">
                    <Image width={100} src={`https://backend.alpagocontracting.com${item.image}`} height={100} />
                  </div>

                  {editModeMap[item._id] ? (
                    <div className="col-sm-6">
                      <EditServiceLogo
                        setEditModeMap={setEditModeMap}
                        itemIds={itemIds}
                        currentPage={currentPage}
                        onClose={() => handleCloseUpload(item._id)}
                      />
                    </div>
                  ) : (
                    <div className="col-sm-6">
                      <button
                        type="primary"
                        onClick={() => {
                          setItemId(item._id); // Set the ID here (replace with your actual ID logic)
                          setIsModalVisible(true);
                        }}
                      >
                        Deleted
                      </button>
                      <button
                        type="primary"
                        onClick={() => handleEditClick(item._id)}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              </>
            </div>
          ))}
        </div>
        <Pagination
          total={totalCount}
          current={currentPage}
          onChange={onPageChange}
          showTotal={(total) => `Total ${total} items`}
        />
      </div>
      <DeleteModal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        handleDelete={handleDelete}
        id={itemId}
      />
    </Fragment>
  );
}

export default ServiceLogo;
