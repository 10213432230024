import React, { Fragment, useEffect } from "react";
import { Button, Form, Input, Spin } from "antd";
import Logo from "../../Assets/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { clearError, clearMessage } from "../../Redux/Reducers/AuthReducer";
import { useNavigate } from "react-router-dom";
import { PostLogin } from "../../Redux/Actions/Auth";


function Login() {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { message, error, loading } = useSelector((state) => state.auth);

  const onFinish = (values) => {
    dispatch(PostLogin(values));
  };
  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/dashboard");
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, message, navigate]);

  return (
    <Fragment>
      <div className="col-sm-12 login">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          data-aos="fade-down"
          data-aos-duration="8000"
        >
          <img src={Logo} alt="img" />
          <Form.Item
            name="Email"
            rules={[{ required: true, message: "Invalid Username" }]}
          >
            <Input placeholder="Email" type="email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Invalid Password" }]}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="save">
              {loading ? (
                <Spin indicator={antIcon} style={{ color: "#ffff" }} />
              ) : (
                "Login"
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment>
  );
}

export default Login;
