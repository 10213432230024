import React, { Fragment, useState } from 'react';
import { message } from "antd";

import ServiceAdd from './ServiceAdd';
import ServiceLogo from './ServiceLogo';


function ServiceText() {
  return (
    <Fragment>
      <div className="col-sm-12 hometext service" data-aos="fade-left">
        <div className="row">
          <div className="col-sm-6">
            <h2>Edit Service Page</h2>
          </div>
          <div className="col-sm-6">
            {/* <div className='uploads'>
                            <h3>Background Image</h3>
                            <Upload {...props} beforeUpload={() => true}> 
                                <Button icon={<UploadOutlined />}>Upload Image</Button>
                            </Upload>
                            <Button type="primary" onClick={handleUploadSubmit} className='save'> SAVE</Button>
                        </div> */}
          </div>

          <div className="col-sm-6 texts service">
            <ServiceAdd />
          </div>

          <div className="col-sm-6 texts service logo">
            <ServiceLogo />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ServiceText;
