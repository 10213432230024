import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  uploaded: 0,
};

const postCompletedSlice = createSlice({
  name: "postCompleted",
  initialState,
  reducers: {
    postCompletedStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
      state.uploaded = null;
    },

    postCompletedSuccess(state, action) {
      state.loading = false;
      state.message = "Project Add Successfully";
      state.uploaded = null;
    },

    postCompletedFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    setCompletedUploadedProgress(state, action) {
      state.uploaded = action.payload;
    },

    clearError(state) {
      state.error = null;
    },

    clearMessage(state) {
      state.message = null;
    },

    clearUpload(state) {
      state.uploaded = null;
    },
  },
});

export const {
  postCompletedStart,
  postCompletedSuccess,
  postCompletedFailure,
  setCompletedUploadedProgress,
  clearError,
  clearMessage,
 
  clearUpload,
} = postCompletedSlice.actions;
export default postCompletedSlice.reducer;