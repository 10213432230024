import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const HomeSlice = createSlice({
  name: "Home",
  initialState,
  reducers: {
    createHomeStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    createHomeSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload.data.data[1];
      state.message = action.payload;
    //   console.log("reducers" ,action.payload)
    },
    logoutHomeSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.message = action.payload;
      state.token = null;
    },

    createHomeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  createHomeStart,
  createHomeSuccess,
  logoutHomeSuccess,
  createHomeFailure,
  clearError,
  clearMessage,
} = HomeSlice.actions;

export default HomeSlice.reducer;