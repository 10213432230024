import React, { Fragment } from "react";

import { Link } from "react-router-dom";
import AllUnder from "./AllUnder";

function Underlist() {
  return (
    <Fragment>
      <div className="col-sm-12 hometext" data-aos="fade-left">
        <div className="row">
          <div className="col-sm-6">
            <h2>Under Constructed Projects</h2>
            <Link to="/add-under-construction-projects" className="save">
              {" "}
              ADD NEW{" "}
            </Link>
          </div>
          {/* <div className="col-sm-6">
              <div className="uploads">
                <h3>Background Image</h3>
                <Upload {...props} beforeUpload={() => true}>
                  {" "}
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>
                <Button
                  type="primary"
                  onClick={handleUploadSubmit}
                  className="save"
                >
                  {" "}
                  SAVE
                </Button>
              </div>
            </div> */}

          <div className="col-sm-12 texts">
            <AllUnder />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Underlist;
