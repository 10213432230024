import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteUnderStart, deleteUnderSuccess, deleteUnderFailure} from "../Reducers/DeleteUnderReducer";
import axios from "axios";


export const DeleteUnder = createAsyncThunk(

  "deleteUnder",

  async (id, thunkAPI) => {
    try {
       
      thunkAPI.dispatch(deleteUnderStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/HardDeleteUnderConstructionProjects/${id}`
      );
      thunkAPI.dispatch(deleteUnderSuccess(response));
    

      return response;
    } catch (error) {
  
      thunkAPI.dispatch(deleteUnderFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);