import React, { Fragment } from 'react'
import Menu from '../Components/Sidebar/Menu'
import HomeText from '../Components/Home/HomeText'



function Home() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3 side'>
                        <Menu />
                    </div>
                    <div className='col-sm-9 main'>
                        <HomeText />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Home 