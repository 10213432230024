import {
  editUnderStart,
  editUnderSuccess,
  editUnderFailure,
  setEditUnderUploadedProgress,
  clearUpload,
} from "../Reducers/EditUnderReducer";
import axios from "axios";

// export const EditUnder = createAsyncThunk(
//   "editUnder",

//   async ({ formData, id }, thunkAPI) => {
//     try {
//       console.log("data", formData);
//       thunkAPI.dispatch(editUnderStart()); // Dispatch the start action
//       // Make your API request here, e.g., using fetch or axios
//       const response = await axios.put(
//         `${process.env.REACT_APP_BASE_URL}/EditUnderConstructionProjects/${id}`,
//         formData
//       );
//       thunkAPI.dispatch(editUnderSuccess(response));
//       console.log("respponse", response);

//       return response;
//     } catch (error) {
//       thunkAPI.dispatch(editUnderFailure(error.response.data.message)); // Dispatch the failure action
//     }
//   }
// );

export const EditUnder =
  ({ formData, id }) =>
  async (dispatch) => {
    try {
      dispatch(editUnderStart());

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/EditUnderConstructionProjects/${id}`,
        formData,
        {
          onUploadProgress: (data) => {
            const progress = Math.round((data.loaded / data.total) * 100);
            dispatch(setEditUnderUploadedProgress(progress));
          },
        }
      );

      dispatch(editUnderSuccess(response));
      dispatch(clearUpload()); // Set uploaded to null after success
    } catch (error) {
      dispatch(editUnderFailure(error.response.data.message)); //
    }
  };
