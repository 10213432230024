import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const ServiceUpdateSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    createServiceUpdateStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    createServiceUpdateSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload.data.data;
      state.message = "Update Successfully";
    //   console.log("asdsad" ,action.payload)
    },
    logoutServiceUpdateSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.message = action.payload;
      state.token = null;
    },

    createServiceUpdateFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  createServiceUpdateStart,
  createServiceUpdateSuccess,
  logoutServiceUpdateSuccess,
  createServiceUpdateFailure,
  clearError,
  clearMessage,
} = ServiceUpdateSlice.actions;

export default ServiceUpdateSlice.reducer;