import { createAsyncThunk } from "@reduxjs/toolkit";
import { qouteStart, qouteSuccess, qouteFailure,} from "../Reducers/QouteReducer";
import axios from "axios";
import { toast } from "react-toastify";


export const Qoute = createAsyncThunk(

  "Qoute",

  async ({currentPage}, thunkAPI) => {
    try {
        // console.log("data" , Category)
        // console.log("data" , values)
      thunkAPI.dispatch(qouteStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/GetQuoteLeadListing?page=${currentPage}`
      );
      thunkAPI.dispatch(qouteSuccess(response));
      // console.log("respponse", response)

      return response;
    } catch (error) {
      console.error(error)
      toast.error(error.response.message);
      thunkAPI.dispatch(qouteFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);