import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const ServicePostSlice = createSlice({
  name: "servicepost",
  initialState,
  reducers: {
    createServicePostStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    createServicePostSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload.data.data;
      state.message = "Add Successfully";
      // console.log("asdsad" ,action.payload)
    },
    logoutServicePostSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.message = action.payload;
      state.token = null;
    },

    createServicePostFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  createServicePostStart,
  createServicePostSuccess,
  logoutServicePostSuccess,
  createServicePostFailure,
  clearError,
  clearMessage,
} = ServicePostSlice.actions;

export default ServicePostSlice.reducer;