import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const BrandSlice = createSlice({
  name: "Brands",
  initialState,
  reducers: {
    getBrandStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getBrandSuccess(state, action) {
      state.loading = false;
   
      state.data = action.payload.data;
      state.message = action.payload;
 
    },

    getBrandFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getBrandStart,
  getBrandSuccess,

  getBrandFailure,
  clearError,
  clearMessage,
} = BrandSlice.actions;

export default BrandSlice.reducer;