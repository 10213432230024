import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getDashboardStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getDashboardSuccess(state, action) {
      state.loading = false;

      state.data = action.payload.data;
      state.message = action.payload;
    },

    getDashboardFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getDashboardStart,
  getDashboardSuccess,

  getDashboardFailure,
  clearError,
  clearMessage,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
