import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const QouteSlice = createSlice({
  name: "Qoute",
  initialState,
  reducers: {
    qouteStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    qouteSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;

    },


    qouteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  qouteStart,
  qouteSuccess,
  logoutqouteSuccess,
  qouteFailure,
  clearError,
  clearMessage,
} = QouteSlice.actions;

export default QouteSlice.reducer;