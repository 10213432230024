import './App.css';
import './Style/Style.css';
import './Style/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Fragment,useEffect  } from 'react';
import { Route, Routes } from "react-router-dom";

import Home from "./Layout/Home";
import Dashboard from "./Layout/Dashboard";
import About from "./Layout/About";
import AOS from "aos";
import "aos/dist/aos.css";
import LoginPage from "./Layout/LoginPage";
import Service from "./Layout/Service";
import Completed from "./Layout/Completed";
import EditCompleteProjects from "./Layout/EditCompleteProjects";
import Under from "./Layout/Under";
import EditUnderConstruction from "./Layout/EditUnderConstruction";
import AllLeads from "./Layout/AllLeads";
import ViewAllLeads from "./Layout/ViewAllLeads";
import ProtectedRoute from "./Components/ProtectedRoutes/ProtectedRoute";
import AddCompletedProjects from "./Layout/AddCompletedProjects";
import AddUnderConstructedProject from "./Layout/AddUnderConstructedProject";
import ChangePassword from "./Layout/ChangePassword";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

function App() {
  const { token } = useSelector((state) => state.auth);
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);

  const userToken = Cookies.get("token");

  const tokens = token ? token : userToken;

  return (
    <Fragment>
      <Routes>
        <Route path="/" exact element={<LoginPage />} />

        <Route
          path="/dashboard"
          exact
          element={
            <ProtectedRoute token={tokens}>
              {" "}
              <Dashboard />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          exact
          element={
            <ProtectedRoute token={tokens}>
              <Home />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/about"
          exact
          element={
            <ProtectedRoute token={tokens}>
              <About />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/service"
          exact
          element={
            <ProtectedRoute token={tokens}>
              <Service />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/lead"
          exact
          element={
            <ProtectedRoute token={tokens}>
              <AllLeads />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/complete-projects"
          exact
          element={
            <ProtectedRoute token={tokens}>
              <Completed />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/under-construction-projects"
          exact
          element={
            <ProtectedRoute token={tokens}>
              {" "}
              <Under />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-leads/:id"
          exact
          element={
            <ProtectedRoute token={tokens}>
              {" "}
              <ViewAllLeads />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-complete-projects"
          exact
          element={
            <ProtectedRoute token={tokens}>
              <EditCompleteProjects />{" "}
            </ProtectedRoute>
          }
        />

        <Route
          path="/add-complete-projects"
          exact
          element={
            <ProtectedRoute token={tokens}>
              <AddCompletedProjects />{" "}
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-under-construction-projects"
          exact
          element={
            <ProtectedRoute token={tokens}>
              {" "}
              <EditUnderConstruction />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-under-construction-projects"
          exact
          element={
            <ProtectedRoute token={tokens}>
              <AddUnderConstructedProject />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/change-password"
          exact
          element={
            <ProtectedRoute token={tokens}>
              <ChangePassword />{" "}
            </ProtectedRoute>
          }
        />
      </Routes>
    </Fragment>
  );
}

export default App;
