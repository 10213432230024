import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
} from "../Reducers/ChangePasswordReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const ChangePassword = createAsyncThunk(
  "changePassword",

  async (data, thunkAPI) => {
    try {
      console.log("data", data);
      thunkAPI.dispatch(changePasswordStart());

      const token = Cookies.get("token");
      console.log(token, "token");
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/ChangePassword`,
        data,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      thunkAPI.dispatch(changePasswordSuccess(response));
      console.log("respponse", response);

      return response;
    } catch (error) {
      thunkAPI.dispatch(changePasswordFailure(error.response.data.message)); // Dispatch the failure action
    }
  }
);
