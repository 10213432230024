import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDashboardStart,
  getDashboardSuccess,
  getDashboardFailure,
} from "../Reducers/GetDashboard";
import axios from "axios";

export const GetDashboard = createAsyncThunk(
  "dashboard",

  async (_, thunkAPI) => {
    try {
      // console.log("data" , Category)
      // console.log("data" , values)
      thunkAPI.dispatch(getDashboardStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/AllProjectsCountAllToAll`
      );
      thunkAPI.dispatch(getDashboardSuccess(response));

      return response;
    } catch (error) {
      thunkAPI.dispatch(getDashboardFailure(error.response.data.message)); // Dispatch the failure action
    }
  }
);
