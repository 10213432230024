import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const HomeUpdateSlice = createSlice({
  name: "Home",
  initialState,
  reducers: {
    createHomeUpdateStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    createHomeUpdateSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload.data.data[0];
      state.message = "Update Successfully";
    //   console.log("asdsad" ,action.payload)
    },
    logoutHomeUpdateSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.message = action.payload;
      state.token = null;
    },

    createHomeUpdateFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  createHomeUpdateStart,
  createHomeUpdateSuccess,
  logoutHomeUpdateSuccess,
  createHomeUpdateFailure,
  clearError,
  clearMessage,
} = HomeUpdateSlice.actions;

export default HomeUpdateSlice.reducer;