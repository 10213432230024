import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBrandStart, getBrandSuccess, getBrandFailure} from "../Reducers/GetBrandsReducer";
import axios from "axios";


export const GetBrands = createAsyncThunk(

  "Brands",

  async ({currentPage}, thunkAPI) => {
    try {
        // console.log("data" , Category)
        // console.log("data" , values)
      thunkAPI.dispatch(getBrandStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/GetBrandListing?page=${currentPage}`
      );
      thunkAPI.dispatch(getBrandSuccess(response));
      console.log("respponse", response)

      return response;
    } catch (error) {
  
      thunkAPI.dispatch(getBrandFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);