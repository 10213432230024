import React, { Fragment, useEffect, useState } from "react";
import Create from "../../Assets/Create.png";

import Delete from "../../Assets/Delete.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CompeletedProjects } from "../../Redux/Actions/CompletedProject";
import { Pagination } from "antd";
import DeleteModal from "../Modal/DeleteModal";
import { DeleteCompleted } from "../../Redux/Actions/DeleteCompleted";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../Redux/Reducers/DeleteCompletedReducer";

function Allcompleted() {
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.completed);
  const { message, error } = useSelector((state) => state.deleteCompleted);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemId, setItemId] = useState(null);
  useEffect(() => {
    setTotalCount(data.totalcount);
  }, [data.totalcount]);

  const dispatch = useDispatch();

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = async (id) => {
    await dispatch(DeleteCompleted(id));
    dispatch(CompeletedProjects({ currentPage }));
  };

  const imageCounts = data?.data?.map((item) => {
    const images = item.CompletedProjectData.filter(
      (data) => data.type.split("/")[0] === "image"
    );
    return { Name: item.Name, count: images.length };
  });

  const handleNavigate = (elem) => {
    navigate(`/edit-complete-projects`, {
      state: {
        data: elem,
      },
    });
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch(CompeletedProjects({ currentPage }));
  }, [currentPage, dispatch]);

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, message]);


  return (
    <Fragment>
      <div className="lists  leads">
        <table>
          <tbody>
            {data?.data?.map((elem, ind) => (
              <tr key={ind}>
                <td>
                  <p>{elem.Name}</p>
                </td>
                <td className="op">{imageCounts[ind]?.count} images</td>
                <td className="op">
                  {" "}
                  {elem?.CompletedProjectData?.length -
                    imageCounts[ind]?.count}{" "}
                  Videos
                </td>
                <td onClick={() => handleNavigate(elem)}>
                  <img src={Create} alt="view" />{" "}
                </td>

                <td
                  onClick={() => {
                    setItemId(elem._id); // Set the ID here (replace with your actual ID logic)
                    setIsModalVisible(true);
                  }}
                >
                  <img src={Delete} alt="view" />{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          total={totalCount}
          current={currentPage}
          onChange={onPageChange}
          showTotal={(total) => `Total ${total} items`}
        />
      </div>
      <DeleteModal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        handleDelete={handleDelete}
        id={itemId}
      />
    </Fragment>
  );
}

export default Allcompleted;
