import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteQueryStart,
  deleteQuerySuccess,
  deleteQueryFailure,
} from "../Reducers/DeleteQueries";
import axios from "axios";
import { toast } from "react-toastify";

export const QuerisDeleteData = createAsyncThunk(
  "QueriesDelete",

  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteQueryStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/HardDeleteQuoteLead/${id}`
      );
      console.log("delete id", id);
      thunkAPI.dispatch(deleteQuerySuccess(response));
      // console.log("respponseedit", response)

      return response;
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
      thunkAPI.dispatch(deleteQueryFailure(error.response.data.message)); // Dispatch the failure action
    }
  }
);
