import { Modal } from "antd";
import React from "react";

const DeleteModal = ({ visible, onOk, onCancel, id, handleDelete }) => {
  const handleOk = () => {
    onOk(id); // Pass the ID to the onOk function
    handleDelete(id);
  };
  return (
    <Modal
      className="deleteModal"
      centered
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <h6>Are you sure you want to delete this item?</h6>
    </Modal>
  );
};

export default DeleteModal;
