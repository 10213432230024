import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAboutStart, createAboutSuccess, createAboutFailure,} from "../Reducers/AboutReducer";
import axios from "axios";
import { toast } from "react-toastify";


export const about = createAsyncThunk(

  "about",

  async (_, thunkAPI) => {
    try {
        // console.log("data" , Category)
        // console.log("data" , values)
      thunkAPI.dispatch(createAboutStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/GetContentListing`
      );
      thunkAPI.dispatch(createAboutSuccess(response));
    //   console.log("respponse", response)

      return response;
    } catch (error) {
      console.error(error)
      toast.error(error.response.message);
      thunkAPI.dispatch(createAboutFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);