import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
};

const DeleteQuriesSlice = createSlice({
  name: "QueriesDelete",
  initialState,
  reducers: {
    deleteQueryStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    deleteQuerySuccess(state, action) {
      state.loading = false;
      state.message = action.payload.data.data.message;
    },

    deleteQueryFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  deleteQueryStart,
  deleteQuerySuccess,
  deleteQueryFailure,
  clearError,
  clearMessage,
} = DeleteQuriesSlice.actions;

export default DeleteQuriesSlice.reducer;
